import { findMenu } from '../../../utils/util'
import { mapState } from 'vuex'

const mixin = {
  data () {
    return {
      selectedMenu: { meta: {} }
    }
  },
  computed: {
    ...mapState({
      // 主路由
      mainMenu: state => state.user.columnItems
    })
  },
  created () {
    const routes = this.$route.matched.concat()
    this.selectedMenu = findMenu(this.mainMenu, routes.pop().path, 3)
  }
}

export default mixin