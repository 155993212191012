<template>
  <div class="account-settings-info-right">
    <div class="account-settings-info-title" style="display: flex;justify-content: space-between">
      <span>{{selectedMenu.meta.title}}</span>
    </div>
    <a-card :bordered="false" class="m-t-24">
      <div class="fs-search-box" ref="searchBoxRef">
        <div class="table-page-search-wrapper m-b-none">
          <a-form layout="inline">
            <a-form-item label="操作时间">
              <a-range-picker
                :value="selectedTime"
                format="YYYY-MM-DD"
                :allowClear="true"
                :placeholder="['开始时间', '结束时间']"
                @panelChange="panelChange"
                @openChange="openChange"
                @change="selectedTimeOnchange">
              </a-range-picker>
            </a-form-item>
            <a-form-item label="操作人">
              <a-input v-model="searchParams.operator" placeholder="请输入操作人" allowClear/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="loadLogList" icon="search">查询</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-card>
    <div>
      <a-table :columns="columns"
               :dataSource="logList"
               :pagination="pagination"
               @change="tableChange"
               :rowKey="(record) => record.id"
               :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">{{searchParams.number*searchParams.size+index+1}}
        </template>
      </a-table>
    </div>
    <a-modal title="日志清理"
             width="400px"
             v-model="clearLoggerModal">
      <p>选择需要清理的日志时间段</p>
      <a-range-picker :showTime="{ format: 'HH:mm' }"
                      format="YYYY-MM-DD HH:mm"
                      :placeholder="['开始时间', '结束时间']"
                      @change="onClearTimeChange"/>
      <p style="color: #ed4014;margin-top: 16px;">
        备注：此操作会清空所选时间段的所有日志（不选择时间段将会清空所有日志，双击选择相同时间清空选中时间之前的所有日志），确认清除请点击确认按钮。</p>
      <template slot="footer">
        <a-button key="back" @click="handleClearModalCancel">取消</a-button>
        <a-button key="submit" type="danger" :loading="clearLoading" @click="handleClear">
          清理
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
  import menuTile from '../../common/mixins/menuTitle'
  import SERVICE_URLS from '../../../api/service.url'
  import moment from 'moment'

  const columns = [
    {
      title: '序号',
      width: '60px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '操作人',
      width: '80px',
      align: 'center',
      dataIndex: 'operator'
    },
    {
      title: '操作时间',
      width: '100px',
      align: 'center',
      dataIndex: 'operateTime'
    },
    {
      title: '模块名称',
      width: '100px',
      align: 'center',
      dataIndex: 'moduleName'
    },
    {
      title: 'ip地址',
      width: '80px',
      align: 'center',
      dataIndex: 'ip'
    },
    {
      title: '日志类型',
      width: '100px',
      align: 'center',
      dataIndex: 'logType'
    },
    {
      title: '操作内容',
      width: '80px',
      align: 'center',
      dataIndex: 'content'
    }
  ]
  export default {
    name: 'Logs',
    mixins: [ menuTile ],
    data () {
      return {
        logList: [],
        selectedTime: [],
        format: 'YYYY-MM-DD',
        columns,
        searchParams: {
          number: 0,
          size: 10,
          startTime: '',
          endTime: ''
        },
        pagination: {
          total: 0,
          pageSize: 10,
          showTotal: total => `共 ${total} 条数据`
        },
        tableLoading: false,
        showDrawer: false,
        detailLoading: false,
        logDetail: {
          // 异常信息
          loggingEvent: '',
          dateCreated: '',
          // 异常堆栈信息
          loggingEventExceptions: [],
          // 异常参数信息
          loggingEventProperties: []
        },
        loggingEventPropertiesColumns: [
          {
            title: '序号',
            align: 'center',
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '参数名',
            align: 'center',
            dataIndex: 'mappedKey'
          },
          {
            title: '值',
            align: 'center',
            dataIndex: 'mappedValue'
          }
        ],
        clearLoggerModal: false,
        clearDate: [],
        clearLoading: false
      }
    },
    methods: {
      changeDatePicker (date) {
        this.searchParams.startTime = moment(date[0]).format(this.format)
        this.searchParams.endTime = moment(date[1]).format(this.format)
        this.loadLogList()
      },
      openChange (status) {
        this.$nextTick(() => {
          if (!status) {
            this.searchParams.startTime = this.selectedTime[0].format('YYYY-MM-DD')
            this.searchParams.endTime = this.selectedTime[1].format('YYYY-MM-DD')
          }
        })
      },
      selectedTimeOnchange (value) {
        this.selectedTime = value
        if (value.length === 0) {
          this.searchParams.startTime = ''
          this.searchParams.endTime = ''
        }
      },
      loadLogList () {
        if (!this.tableLoading) {
          this.tableLoading = true
          this.$http(this, {
            url: SERVICE_URLS.earlBase.logger.search,
            data: this.searchParams,
            noTips: true,
            success: (data) => {
              this.logList = data.body.content
              this.pagination.total = data.body.totalElements
              this.tableLoading = false
            },
          })
        }
      },
      tableChange (e) {
        this.searchParams.number = e.current - 1
        this.loadLogList()
      },
      handleDrawerClose () {
        this.showDrawer = false
      },
      handleShowDrawer (id) {
        // console.log(id)
        if (!this.detailLoading) {
          this.detailLoading = true
          this.showDrawer = true
          this.$http(this, {
            url: SERVICE_URLS.earlBase.logger.logDetail,
            params: { id },
            noTips: true,
            success: (data) => {
              this.logDetail = data.body
              this.detailLoading = false
            }
          })
        }
      },
      onClearTimeChange (value, dateString) {
        this.clearDate = dateString
      },
      handleClearModalCancel () {
        this.clearLoggerModal = false
      },
      handleClear () {
        if (!this.clearLoading) {
          this.clearLoading = true
          let data = {}
          if (this.clearDate.length > 0) {
            data = {
              startDate: this.clearDate[0],
              endDate: this.clearDate[1]
            }
          }
          this.$http(this, {
            url: SERVICE_URLS.earlBase.logger.logsClear,
            successTipsContent: '日志清理成功',
            data,
            success: () => {
              this.clearLoading = false
              this.searchParams.number = 0;
              this.loadLogList();
              this.handleClearModalCancel();
            }
          });
        }
      },
      panelChange (value, mode) {
        this.selectedTime = value
      },
    },
    created () {
      this.loadLogList()
    },
    mounted() {
    },
    filters: {
      timeFilter (time) {
        if (time) {
          return moment(time).format('YYYY年MM月DD日 HH:mm:ss')
        } else {
          return '-'
        }
      }
    }
  }
</script>

<style scoped>

</style>